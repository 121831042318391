import ProjectData from '@/data/ProjectData.ts'

export default [
  new ProjectData("project-11", "Unity Multiplayer - WebSockets Demo", "img/projects/others/multiplayer-websockets/multiplayer01.JPG", `
      <div class="paragraph">
        A multiplayer game demo using Web Sockets
      </div>

      <div class="paragraph center">
        <iframe class="youtube" src="https://www.youtube.com/embed/7PJjT-e-DS0" frameborder="0" allowfullscreen></iframe>
      </div>

      <div class="paragraph-content"  >
        This is an example of a Unity client that uses WebSockets to send the current players information.
        <br/><br/>This Client is a cross platform app that can be used on Windows, Android and MacOS.
        <br/><br/>This WebSocket client is working with a NodeJS server on multiplayer-websocket-server-node.<br/><br/>
      </div>

      <div class="paragraph">
        <div class="notice">
          Source code available on <a href="https://github.com/krlosflip22/multiplayer-websocket-client-unity" target="_blank">GitHub</a>.
        </div>
      </div>

      <div class="paragraph center">
          <img class="pc-screenshot" src="img/projects/others/multiplayer-websockets/multiplayer02.JPG"/>
          <img class="pc-screenshot" src="img/projects/others/multiplayer-websockets/multiplayer03.JPG"/>
      </div>
    `, "#ff75b3", false, true),
  new ProjectData("project-7", "Huqariq App", "img/projects/others/huqariq/hm.jpg", `
      <div class="paragraph">
        A TTS app that will speak in Quechua language
      </div>

      <div class="paragraph center">
        <iframe class="youtube" src="https://www.youtube.com/embed/4hk_tV9sYVs" frameborder="0" allowfullscreen></iframe>
      </div>

      <div class="paragraph-content"  >
        This app was made with Kotlin and was part of a research project at the National University of Engineering. The project consists
        on a ways to use technology with old peruvian languages.
        <br/><br/>The TTS bot now is working on spanish and will use Quechua language soon. The avatar was built on Unity and embedded to the
        native app.<br/><br/>
      </div>

      <div class="paragraph">
        <div class="notice">
          Source code available on <a href="https://github.com/krlosflip22/huqariq-app-tts" target="_blank">GitHub</a>.
        </div>
      </div>

      <div class="paragraph center">
          <img class="pc-screenshot-v" src="img/projects/others/huqariq/h0.jpg"/>
          <img class="pc-screenshot-v" src="img/projects/others/huqariq/h1.png"/>
      </div>
    `, "#ff75b3"),
  new ProjectData("project-8", "PPO Delivery Training", "img/projects/others/ppo-delivery/03vehiculo.jpeg", `
      <div class="paragraph">
          An Unity ml-agents project to train a vehicle to deliver products to many destinations
      </div>

      <div class="paragraph center">
        <iframe class="youtube" src="https://www.youtube.com/embed/VNBk1vZfxjo" frameborder="0" allowfullscreen></iframe>
      </div>

      <div class="paragraph-content"  >
        This project was developed in a course at the National University of Engineering.
        <br/><br/>The ML-Agents library was used to train an autonomous vehicle to travel through several streets and deliver products to a group of recipients.
        PPO and A* algorithms were used to train this AI.<br/><br/>
      </div>

      <div class="paragraph">
        <div class="notice">
          Source code available on <a href="https://github.com/krlosflip22/ppo-deliverytraining-unity" target="_blank">GitHub</a>.
        </div>
      </div>

      <div class="paragraph center">
          <img class="pc-screenshot" src="img/projects/others/ppo-delivery/01pista.jpeg" />
          <img class="pc-screenshot" src="img/projects/others/ppo-delivery/02ruta.jpeg" />
          <img class="pc-screenshot" src="img/projects/others/ppo-delivery/03vehiculo.jpeg" />
      </div>
    `, "#ff75b3"),
  new ProjectData("project-9", "Shoot the Capsules VR", "img/projects/others/08-shoot/08-shoot-profile.jpg", `
    <div class="paragraph">
        VR Game for shooting capsule enemies.
    </div>

    <div class="paragraph center">
        <img class="youtube-like" src="img/projects/others/08-shoot/Shooting1.jpg"/>
    </div>
    
    <div class="paragraph-content" >
        <br/>
        This game was made in a Hackathon in MakerLab Perú.
        I worked with DYI custom VR System using PS Move Controllers, PS Eye and a cardboard with a Mobile Device.
        If you want to use this system, click <a style="text-decoration: none; color:pink" href="https://www.youtube.com/watch?v=AH_pexsuXaE" target="_blank">here</a>.
        <br/><br/>I'd created a script that only show the controllers rotation, that allow me to use the VR System without cameras.
    </div>
    
    <div class="paragraph center">
        <img class="pc-screenshot" src="img/projects/others/08-shoot/Shooting1.jpg"/>
        <img class="pc-screenshot" src="img/projects/others/08-shoot/Shooting2.jpg"/>
        <img class="pc-screenshot" src="img/projects/others/08-shoot/Shooting3.jpg"/>
    </div>`, "#ff75b3"),
  new ProjectData("project-10", "ARKit Demos", "img/projects/others/arkit/arkit01.png", `
    <div class="paragraph">
        Demos using Unity and ARKit for iOS devices.
    </div>

    <div class="paragraph center">
        <iframe class="youtube" src="https://www.youtube.com/embed/SaASLYHr-3Q" frameborder="0" allowfullscreen></iframe>
    </div>

    <div class="paragraph-content" >
        <br/>
        This demo is based on a furniture app that was a project I've worked at the National University of Engineering..
    </div>

    <div class="paragraph center">
        <img class="pc-screenshot-v" src="img/projects/others/arkit/arkit01.png"/>
        <img class="pc-screenshot-v" src="img/projects/others/arkit/arkit02.png"/>
    </div>`, "#ff75b3"),
];