



































import Vue from "vue";

export default Vue.extend({
  name: "ProjectsList",
  props: {
    collaborations: Array,
  },
  data: function () {
    return {
      showPopup: false,
      popupTitle: "",
      popupColor: "",
      popupContent: "",
    };
  },
});
