

















import Vue from "vue";
import CollaborationList from "@/components/CollaborationList.vue";
import collaborationData from "@/data/CollaborationData.ts";

export default Vue.extend({
  name: "Collaborations",
  components: {
    CollaborationList,
  },
  data: function () {
    return {
      collaborations: collaborationData,
    };
  },
});
