import ProjectData from '@/data/ProjectData.ts'

export default [
    new ProjectData("project-14", "STEM Simulations", "img/projects/games/14-sims.jpg",
        `
    <div class="paragraph">
        Simulations for learning STEM topics like Physics, Chemistry, Biology, etc. 
    </div>
    <div class="paragraph center">
        <a href="https://www.inspiritvr.com/physics/simulations/maze-game"><img  class="pc-screenshot" src="img/projects/games/14-sims/sim01.jpg"/></a>
        <a href="https://www.inspiritvr.com/chemistry/simulations/cathode-ray"><img class="pc-screenshot" src="img/projects/games/14-sims/sim02.jpg"/></a>
        <a href="https://www.inspiritvr.com/chemistry/simulations/simple-gas-laws"><img  class="pc-screenshot" src="img/projects/games/14-sims/sim03.jpg"/></a>
    </div>

    <div class="paragraph-content" >
        Those simulations are an innovative and effective approach to teaching and learning in these fields. 
        They involves the use of computer simulations to create a virtual environment that mimics real-world scenarios and situations, 
        allowing students to interact with and explore complex concepts and ideas in a safe and controlled environment.
    </div> 
    `, "#ff75b3", false, true),
    new ProjectData("project-12", "Stranger", "img/projects/games/12-stranger.jpg",
        `
    <div class="paragraph">
        Like Omegle, but for VR.
    </div>
    <div class="paragraph center">
        <iframe class="youtube" src="https://www.youtube.com/embed/K5TpHzZjM1c" frameborder="0" allowfullscreen></iframe> 
    </div>
    <div class="paragraph center">
        <a href="https://www.oculus.com/experiences/quest/4836355389756492" style="text-decoration: none; color:pink" target="_blank">Avaliable on Oculus Quest</a>
    </div>

    <div class="paragraph-content" >
        Meet Strangers in a creepy alley. So much fun.<br/>

        <br/>- Express yourself with your Oculus avatar
        <br/>- Match Strangers and see if there’s a vibe
        <br/>- If yes, travel the metaverse together
        <br/>- If not, hit next and match someone new
    </div>

    <div class="paragraph center">
        <img class="pc-screenshot" src="img/projects/games/12-stranger/stranger01.jpg"/>
        <img class="pc-screenshot" src="img/projects/games/12-stranger/stranger02.jpg"/>
        <img class="pc-screenshot" src="img/projects/games/12-stranger/stranger03.jpg"/>
        <img class="pc-screenshot" src="img/projects/games/12-stranger/stranger04.jpg"/>
    </div>
    `, "#ff75b3", false, true),
    new ProjectData("project-13", "Couch", "img/projects/games/13-couch.jpg",
        `
    <div class="paragraph">
        Watch YouTube™️ together in VR!
    </div>
    <div class="paragraph center">
        <iframe class="youtube" src="https://www.youtube.com/embed/Wll2M0ZojNY" frameborder="0" allowfullscreen></iframe>
    </div>
    <div class="paragraph center">
        <a href="https://www.oculus.com/experiences/quest/4492579950762679" style="text-decoration: none; color:pink" target="_blank">Avaliable on Oculus Quest</a>
    </div>

    <div class="paragraph-content" >
        Meet new people in the lobby
        <br/>Watch videos with friends
        <br/>Watch a movie with your crush

        <br/><br/>On Couch, you can watch any YouTube video in a public or private room. It’s a great way to watch tv with someone on the other side of the world.
    </div>

    <div class="paragraph center">
        <img class="pc-screenshot" src="img/projects/games/13-couch/couch01.jpg"/>
        <img class="pc-screenshot" src="img/projects/games/13-couch/couch02.jpg"/>
        <img class="pc-screenshot" src="img/projects/games/13-couch/couch03.jpg"/>
        <img class="pc-screenshot" src="img/projects/games/13-couch/couch04.jpg"/>
    </div>
    `, "#ff75b3", false, true),
    new ProjectData("project-1", "Cheerio", "img/projects/games/01-cheer-profile.jpg",
        `
    <div class="paragraph">
     Social VR for Dating and Making Friends
    </div>
    <div class="paragraph center">
        <iframe class="youtube" src="https://www.youtube.com/embed/h43pETJHcNY" frameborder="0" allowfullscreen></iframe>
    </div>
    <div class="paragraph center">
        <a href="https://www.oculus.com/experiences/quest/3703371516396691" style="text-decoration: none; color:pink" target="_blank">Avaliable on Oculus Quest</a>
    </div>

    <div class="paragraph-content" >
        The Best Place in VR to Make Friends. Express yourself with creative Holograms.
        Meet other people while traveling around the world. Have an excellent time with others
    </div>

    <div class="paragraph center">
        <img class="pc-screenshot" src="img/projects/games/01-cheer/ch01.JPG"/>
        <img class="pc-screenshot" src="img/projects/games/01-cheer/ch02.JPG"/>
        <img class="pc-screenshot" src="img/projects/games/01-cheer/ch03.JPG"/>
        <img class="pc-screenshot" src="img/projects/games/01-cheer/ch04.JPG"/>
    </div>
    `, "#ff75b3", false, true),
    new ProjectData("project-2", "Hololistic", "img/projects/games/02-hololistic-profile.jpg", `
    <div class="paragraph">
        In this virtual reality experience the user plays as Alexander Von Humboldt's new assistant and is assigned to
        recover his bag from a fox and other animals.
    </div>
    <div class="paragraph center">
        <iframe class="youtube" src="https://www.youtube.com/embed/FpHubM_SCQo" frameborder="0" allowfullscreen></iframe>
    </div>

    <div class="paragraph-content" >
        This software was designed by team Virtual Penguins during the event <strong>"Hackathon Humboldt y las Américas"</strong> (Lima-July 2019/Berlin-September 2019) and has been
        receiving some improvements over time. It is set in a location based on the pervian minning region of Hualgayoc.<br/>
        <br/>The experience consist of narrated parts and also interactive elements. The main theme of this work is the concept of the Holistic.<br/>
        <br/>This demo was presented at Humboldt Forum at Berlin (September 13th and 14th 2019). This virtual reality experience was developed
        with Unity and VRTK v3.
    </div>

    <div class="paragraph center">
    <img class="pc-screenshot" src="img/projects/games/02-holo/Hololistic1.jpg"/>
    <img class="pc-screenshot" src="img/projects/games/02-holo/Hololistic2.jpg"/>
    <img class="pc-screenshot" src="img/projects/games/02-holo/Hololistic4.jpg"/>
    <img class="pc-screenshot" src="img/projects/games/02-holo/Hololistic7.jpg"/>
    <img class="pc-screenshot" src="img/projects/games/02-holo/Hololistic10.jpg"/>
    <img class="pc-screenshot" src="img/projects/games/02-holo/Hololistic11.jpg"/>
    </div>
    `, "#ff75b3"),
    new ProjectData("project-3", "Fix it Vox", "img/projects/games/03-fixit-profile.jpg", `
    <div class="paragraph">
        Multiplayer VR vs PC Game
    </div>
    <div class="paragraph center">
        <iframe class="youtube" src="https://www.youtube.com/embed/V9PVVqhs7NA" frameborder="0" allowfullscreen></iframe>
    </div>

    <div class="paragraph-content" >
        This game was developed on Lima Game Jam 2020 (GGJ 2020) in Pontifical Catholic University of Peru. The game consist in one
        VR Player that destroys towers over the scenario, on the other side, 4 players using a gamepad for each one will repair those
        destroyed towers until the time finishes.
    </div>

    <div class="paragraph center">
        <img class="pc-screenshot" src="img/projects/games/03-fixit/02-fixit-0.jpg"/>
        <img class="pc-screenshot" src="img/projects/games/03-fixit/02-fixit-1.jpg"/>
    </div>
    `, "#ff75b3"),
    new ProjectData("project-4", "A cuidar mi Jardin", "img/projects/games/04-jardin-profile.jpg", `
    <div class="paragraph">
        Rehabilitation game that uses a custom motion tracking suit for therapy exercises.
    </div>

    <div class="paragraph center">
        <iframe class="youtube" src="https://www.youtube.com/embed/ImfOondqslY" frameborder="0" allowfullscreen></iframe>
    </div>
    
    <div class="paragraph-content"  >
        I worked as a programmer in this rehabilitation game created in order to help ischemic stroke patients.
        The project owners is the research group AVATAR PUCP from Pontifical Catholic University of Peru.
        <br/><br/>The app reads the movements using a custom motion tracking suit (property of Pontifical Catholic University of Peru) and XBox One Kinect.
        The suit allowed the application to reproduce the patient movements using a virtual arm, and store the data of them, also export and show stats of the data for further analysis.
        <br/><br/>More about the game <a style="text-decoration: none; color:pink" href="https://investigacion.pucp.edu.pe/grupos/girab/proyecto/videojuego-interactivo-para-la-rehabilitacion-neuromuscular-de-pacientes-con-accidente-cerebro-vascular/" target="_blank">here</a>.
    </div>

    <div class="paragraph center">
        <img class="pc-screenshot" src="img/projects/games/04-jardin/04-jardin-main.jpg"/>
        <img class="pc-screenshot" src="img/projects/games/04-jardin/Exergames3.jpg"/>
        <img class="pc-screenshot" src="img/projects/games/04-jardin/Exergames4.jpg"/>
    </div>
    `, "#ff75b3"),
    new ProjectData("project-5", "Oráculo matemágico", "img/projects/games/05-oraculo-profile.jpg", `
    <div class="paragraph">
       Card game that uses math problems for get and use the cards abilities. This app had been used for complement math classes.
    </div>

    <div class="paragraph center">
        <iframe class="youtube" src="https://www.youtube.com/embed/NEt7YNupySU" frameborder="0" allowfullscreen></iframe>
    </div>
    
    <div class="paragraph-content" >
        I worked in this project as a programmer, using Unity and C#. Most of my task based on development of AI for NPC and connection with database.
        <br/><br/>The game is property of Fundación Telefónica and Pontifical Catholic University of Perú
        <br/><br/>More about the game <a style="text-decoration: none; color:pink" href="https://investigacion.pucp.edu.pe/grupos/edutecnologia/proyecto/oraculo-matemagico-una-aplicacion-para-aprender-matematicas-jugando/" target="_blank">here</a>.
    </div>

    <div class="paragraph center">
        <img class="pc-screenshot" src="img/projects/games/05-oraculo/Oraculo1.jpg"/>
        <img class="pc-screenshot" src="img/projects/games/05-oraculo/Oraculo7.jpg"/>
        <img class="pc-screenshot" src="img/projects/games/05-oraculo/Oraculo8.jpg"/>
    </div>`, "#ff75b3"),
    new ProjectData("project-6", "Wongderland AR", "img/projects/games/06-wongar-profile.jpg", `
    <div class="paragraph">
        Treasure's search game for mobile devices. Made for Children's Day Campaign in Wong Supermarket Toy Store. (Lima, Perú)
    </div>

    <div class="paragraph center">
        <img class="youtube-like" src="img/projects/games/06-wongar/wongmain.jpg"></img>
    </div>
    
    <div class="paragraph-content"  >
        This Augmented Reality game was build for Android and iOS platforms.
        There are 6 characters for find, when all charaters are found, you can wind a 40% discount for Wong Supermarket Toy Store.
        <br/><br/>I worked in this project as developer. This game is property of Alligator Entertainment.
    </div>

    <div class="paragraph center">
        <img class="pc-screenshot-v" src="img/projects/games/06-wongar/Wongderland1.jpg"/>
        <img class="pc-screenshot-v" src="img/projects/games/06-wongar/Wongderland2.jpg"/>
        <img class="pc-screenshot-v" src="img/projects/games/06-wongar/Wongderland3.jpg"/>
    </div>`, "#ff75b3"),
    new ProjectData("project-7", "Wongderland VR", "img/projects/games/07-wongvr-profile.jpg", `
    <div class="paragraph">
        Virtual reality experience for Children's day campaign in Wong Supermarket Toy Store. (Lima, Perú)
    </div>
    
    <div class="paragraph center">
        <iframe class="youtube" src="https://www.youtube.com/embed/YyacGASS89Q" frameborder="0" allowfullscreen></iframe>
    </div>

    <div class="paragraph-content" >
        Wongderland VR is a virtual travel around a floating island that has a creatures called "Wongders".
        This game was made for Windows and HTC Vive with one controller.
        
        <br/><br/>I worked in this project as developer among 3D artists and animators, I used timeline editor, post processing effects and scripts for
        shooting with the HTC Vive controller.
        
        <br/><br/>This game is property of Alligator Entertainment.
    </div>

    <div class="paragraph center">
        <img class="pc-screenshot" src="img/projects/games/07-wongvr/Wongderland1.jpg"/>
        <img class="pc-screenshot" src="img/projects/games/07-wongvr/Wongderland4.jpg"/>
        <img class="pc-screenshot" src="img/projects/games/07-wongvr/Wongderland5.jpg"/>
    </div>`, "#ff75b3"),
    new ProjectData("project-8", "Whiteboard VR", "img/projects/games/11-pizarra/piz02.JPG", `
    <div class="paragraph">
        App for drawing on VR.
    </div>

    <div class="paragraph center">
        <img class="youtube-like" src="img/projects/games/11-pizarra/piz02.JPG"/>
    </div>

    <div class="paragraph-content" >
    <br/>
    This is a VR experience built on A-Frame. Is a whiteboard that can be used on VR and web browsers.
    <br/><br/>This app is property of Nu Studio.
    </div>
    
    <div class="paragraph center">
        <img class="pc-screenshot" src="img/projects/games/11-pizarra/piz01.JPG"/>
        <img class="pc-screenshot" src="img/projects/games/11-pizarra/piz02.JPG"/>
        <img class="pc-screenshot" src="img/projects/games/11-pizarra/piz03.JPG"/>
    </div>`, "#ff75b3"),
    new ProjectData("project-9", "Escape the Prison Room 2", "img/projects/games/09-prison-profile.jpg", `
    <div class="paragraph">
        Mobile puzzle game
    </div>

    <div class="paragraph center">
        <iframe class="youtube" src="https://www.youtube.com/embed/dZcXML5mrFg" frameborder="0" allowfullscreen></iframe>
    </div>
    
    <div class="paragraph-content"  >
        Escape the Prison Room 2 is a puzzle game that consists in search for clues in a room, when the clues had been found you can pass for the next room.
        That is the first game that I upload in a store, I worked in this game in a game studio called Laberinto BTL, and I developed this for Windows Phone Store.
    </div>

    <div class="paragraph center">
        <img class="pc-screenshot" src="img/projects/games/09-escape/Escape4.jpg"/>
        <img class="pc-screenshot" src="img/projects/games/09-escape/Escape7.jpg"/>
        <img class="pc-screenshot" src="img/projects/games/09-escape/Escape1.jpg"/>
    </div>`, "#ff75b3")
];

//SaASLYHr-3Q